<template>
  <div class="col-md-12 col-lg-10 center">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol>
            <CAlert :color="alertColor" closeButton :show.sync="alertShow">
              {{ alertMessage }}
            </CAlert>
          </CCol>
        </CRow>
        <CRow class="align-items-center">
          <CCol>
            Mindtrex Tutor a personalized learning assistant for school
            students. Mindtrex Tutor is designed to help students grasp various
            subjects and improve their understanding. Create new tutoring
            session to start.
          </CCol>
          <CCol md="auto">
            <div class="text-right">
              <CButton color="primary" @click="addSession">
                New Session
              </CButton>
            </div>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <CDataTable :items="sessions" :fields="fields" tableFilter pagination hover striped sorter>
          <template #tutor_session_id="{ item }">
            <td>
              <CLink @click="sessionDetail(item.tutor_session_id)">{{
                item.tutor_session_id
              }}</CLink>
            </td>
          </template>
          <template #created_at="{ item }">
            <td>
              {{ item.timestamp }}
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from "axios";
import { AUTH_LOGOUT } from "../store/actions/auth";

export default {
  name: "Dashboard",
  data() {
    return {
      alertColor: "success",
      alertShow: false,
      alertMessage: "",
      sessions: [],
    };
  },
  props: {
    fields: {
      type: Array,
      default() {
        return [
          "tutor_session_id",
          "grade",
          "subject",
          "chapter",
          "model",
          "created_at",
        ];
      },
    },
  },
  mounted() {
    axios
      .get(config.VUE_APP_ENV_TUTOR_URL + "/sessions")
      .then((resp) => {
        this.sessions = resp.data.session;
      })
      .catch((err) => {
        if (err.message.includes("401")) {
          this.$store.dispatch(AUTH_LOGOUT).then(() => {
            this.$router.push("/login");
          });
        } else {
          this.showAlert("warning", "Failed to list sessions : " + err);
        }
      });
  },
  methods: {
    sessionDetail(id) {
      this.$router.push("/sessions/" + id);
    },
    addSession() {
      this.$router.push("/sessions/new");
    },
    showAlert(color, messsage) {
      this.alertShow = true;
      this.alertColor = color;
      this.alertMessage = messsage;
    },
  },
};
</script>

<style scoped>
.center {
  margin: 0 auto;
}
</style>
